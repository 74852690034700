"use client";
import { Box, Stack, Typography } from "@mui/material";
import Image from "next/legacy/image";
import React, { CSSProperties } from "react";

const sharedStyles: CSSProperties = {
  fontSize: "25px",
  fontWeight: "bold",
  color: "#11142c",
  textTransform: "uppercase",
  textAlign: "center",
  lineHeight: "26px",
};

const NotFound = () => {
  return (
    <Stack
      spacing={7}
      style={{
        backgroundColor: "#ebebf0",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        style={{
          width: "330px",
          height: "424px",
          padding: "14px 25px 2px",
          border: "solid 1px #6b687d",
          backgroundColor: "#fff",
          flexDirection: "column",
          justifyContent: "space-evenly",
          display: "flex",
        }}
      >
        <Typography style={sharedStyles}>something went wrong</Typography>
        <Image
          src="/image/vickrey.jpeg"
          alt="William Vickrey"
          title="William Vickrey"
          width="280"
          height="277"
          objectFit="cover"
        />
        <Typography style={sharedStyles}>Go home</Typography>
      </Box>
      <Box>
        <Typography
          style={{
            fontSize: "40px",
            fontWeight: "bold",
            color: "#11142c",
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          404
        </Typography>
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "normal",
            color: "#11142c",
            textAlign: "center",
          }}
        >
          Not found
        </Typography>
        <Typography variant="subtitle1">
          The resource requested could not be found on this server!
        </Typography>
      </Box>
    </Stack>
  );
};

export default NotFound;
